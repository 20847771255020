import {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import { Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { cleanObject } from '../../utils/object';

import BulkActions from './BulkActions';
import usePagination from './usePagination';
import useFilter from './useFilter';
import { getFilteredValue, getWhereFilter, normalizeOrderValue } from './utils';

const layoutPaddingTop = 15;
const footerHeight = 75;
const paginationHeight = 56;

const CCCTable = forwardRef(
  (
    {
      rowKey,
      query,
      queryKey,
      where: parentScopeWhere,
      actions = [],
      onChange,
      columns,
      ...props
    }: any,
    ref: any,
  ) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const {
      getColumnSearchProps,
      getColumnRadioFilterProps,
      getColumnNumberSearch,
    } = useFilter(columns);

    const tableWrapRef = useRef<any>({ current: null });
    const pagination = usePagination();
    const [scroll, setScroll] = useState<any>();
    const [order, setOrder] = useState<any>();
    const [where, setWhere] = useState<any>();

    const [queryParamsParsed, setQueryParamsParsed] = useState(false);

    // Parse query params on initial render
    useEffect(() => {
      const params = queryString.parse(location.search);

      const initialPagination = {
        page: params.page ? Number(params.page) : pagination.pagination.page,
        size: params.size
          ? Number(params.size)
          : pagination.pagination.pageSize,
      };

      const initialOrder = params.sortField
        ? {
            field: params.sortField,
            order: params.sortOrder === 'DESC' ? 'DESC' : 'ASC',
          }
        : undefined;

      const initialWhere = params.filters
        ? JSON.parse(params.filters as string)
        : {};

      pagination.pagination.onShowSizeChange(
        initialPagination.page,
        initialPagination.size,
      );

      pagination.handleChange({ current: initialPagination.page });
      setOrder(initialOrder);

      setWhere(() => handleTableChangeFiltering(initialWhere));
      setQueryParamsParsed(true);
    }, []);

    const { data, loading, error, refetch } = useQuery(query, {
      variables: {
        pagination: pagination.getPagination(),
        order: order ? [order] : undefined,
        where: {
          ...parentScopeWhere,
          ...where,
        },
      },
      skip: !queryParamsParsed,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
      data && !error && pagination.handleTotal(data[queryKey].total);
    }, [data, pagination, error, queryKey]);

    const dataSource = useMemo(
      () => (data && !error ? data[queryKey].list : []),
      [data, error, queryKey],
    );

    useImperativeHandle(ref, () => ({
      loading,
      selectedRowKeys,
      setSelectedRowKeys,

      pagination,
      refetch,

      // helpers
      getColumnSearchProps,
      getColumnNumberSearch,
      getColumnRadioFilterProps,
    }));

    useEffect(() => {
      const calculateScroll = (timeout = 0) =>
        setTimeout(() => {
          const tableBody = tableWrapRef.current.querySelector(
            '.ant-table-tbody',
          ) as HTMLTableSectionElement;

          const tableBodyTop = tableBody?.getBoundingClientRect().top;

          const scrollYOffset =
            tableBodyTop + layoutPaddingTop + footerHeight + paginationHeight;

          setScroll({
            y: Math.max(240, window.innerHeight - scrollYOffset),
            x: 'max-content',
          });
        }, timeout);

      calculateScroll(10);
      window.addEventListener('orientationchange', calculateScroll as any);
      return () =>
        window.removeEventListener('orientationchange', calculateScroll as any);
    }, [data]);

    const handleTableChangeFiltering = (_where: any) => {
      const result = Object.keys(_where).reduce(
        (accumulator: any, key: any) => {
          const column = columns.find(
            ({ dataIndex, key: k }: any) => dataIndex === key || k === key,
          );

          if (Array.isArray(_where[key]) && _where[key]?.length) {
            if (!_where[key].includes(null)) {
              accumulator[key] = getWhereFilter(column, _where[key]);
            } else if (_where[key]?.length > 1) {
              const firstArg = {},
                secondArg = {};

              _.set(
                firstArg,
                key,
                getWhereFilter(
                  column,
                  _where[key].filter((value: any) => value),
                ),
              );
              _.set(secondArg, key, { isNull: true });
              const orStatement = [firstArg, secondArg];

              if (accumulator.or) {
                accumulator.and = [{ or: accumulator.or }, { or: orStatement }];
                delete accumulator.or;
              } else {
                accumulator.or = orStatement;
              }
            } else {
              accumulator[key] = { isNull: true };
            }
          }
          accumulator = Object.keys(accumulator).reduce(
            (calc: Record<string, string>, key) => {
              const fields = key.split('|');
              if (fields?.length === 1) {
                _.set(calc, key, accumulator[key]);
              } else if (fields?.length > 1) {
                const resultObject: any = [];
                fields.forEach((field) => {
                  const object = {};
                  _.set(object, field, accumulator[key]);
                  resultObject.push(object);
                });
                _.set(calc, 'or', resultObject);
              }
              return calc;
            },
            {},
          );
          return accumulator;
        },
        {},
      );
      return result;
    };

    const getTableColumns = (columns: any, order: any, where: any) => {
      return columns.map((col: any) => {
        const orderFieldKey = Array.isArray(col.dataIndex)
          ? col.dataIndex.join('.')
          : col.dataIndex;

        const sortOrder =
          order && order.field === orderFieldKey
            ? order.order === 'DESC'
              ? 'descend'
              : 'ascend'
            : null;

        const filteredValue = getFilteredValue(col, where);

        // sync initial sorting and filtering to Table UI
        return {
          ...col,
          sortOrder,
          filteredValue,
        };
      });
    };

    return (
      <div ref={tableWrapRef}>
        <BulkActions
          rows={
            data
              ? selectedRowKeys.map((id: number) =>
                  dataSource.find((c: any) => c[rowKey] === id),
                )
              : []
          }
          actions={actions}
        />
        <Table
          tableLayout="fixed"
          rowKey={rowKey}
          pagination={pagination.pagination}
          dataSource={dataSource}
          loading={loading}
          rowSelection={
            actions.filter((a: any) => !!a.run || !!a.runItem)?.length
              ? {
                  selectedRowKeys,
                  onChange: setSelectedRowKeys,
                  hideDefaultSelections: true,
                }
              : undefined
          }
          columns={getTableColumns(columns, order, where)}
          scroll={scroll}
          onChange={useCallback(
            (p: any, _where: any, sorter: any) => {
              setSelectedRowKeys([]);
              const where = handleTableChangeFiltering(_where);

              setWhere(where);
              const order = sorter.column
                ? {
                    field: normalizeOrderValue(
                      sorter.column.sorter,
                      sorter.column.dataIndex,
                    ),
                    order: sorter.order === 'descend' ? 'DESC' : 'ASC',
                  }
                : null;

              setOrder(order);

              pagination.handleChange(p);

              const filters = cleanObject(_where);
              // create query params and sync it to URL
              const queryParams = {
                page: p.current,
                size: p.pageSize,
                sortField: Array.isArray(sorter.field)
                  ? sorter.field.join('.')
                  : sorter.field,
                sortOrder: sorter.order === 'descend' ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
              };

              navigate({
                search: queryString.stringify(queryParams),
              });

              onChange && onChange.call(null, p, _where, sorter);
            },
            [pagination, columns, onChange],
          )}
          {...props}
        />
      </div>
    );
  },
);

CCCTable.displayName = 'CCCTable';

export default CCCTable;
