import React, { useState } from 'react';
import { Select, Modal } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_CORPORATE_CLIENT_STATUSES } from 'queries/DictionariesQueries';
import { CorporateClientStatuses } from '../../constants/CorporateClientStatuses';

const { Option } = Select;

const CorporateClientStatusSelect = ({ prevValue, onChange, ...rest }: any) => {
  const { data, error } = useQuery(QUERY_LIST_CORPORATE_CLIENT_STATUSES);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    onChange(prevValue);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Select
        style={{ width: '100%' }}
        {...rest}
        onChange={onChange}
        onSelect={(value: string) => {
          if (Number(value) === CorporateClientStatuses.Deactivated) {
            showModal();
          }
        }}
      >
        {data && !error
          ? data.listCorporateClientStatus.map((l: any) => (
              <Option key={l.status_id} value={`${l.status_id}`}>
                {l.name}
              </Option>
            ))
          : null}
      </Select>
      <Modal
        title="Do you really want to deactivate this corporate client?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>It will orphan all the users in this community.</p>
        <p>
          Please contact a developer to make sure the users from the community
          are inactive.
        </p>
        <p>
          If so, click &quot;OK&quot; and then click &quot;Update&quot; button.
        </p>
      </Modal>
    </div>
  );
};

CorporateClientStatusSelect.displayName = 'CorporateClientStatusSelect';

export default CorporateClientStatusSelect;
