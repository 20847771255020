import React, { useContext, useEffect, useMemo } from 'react';
import { User } from 'firebase/auth';
import { AuthContext } from './AuthContext';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Permissions from '../constants/Permissions';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      user_id
      role_id
      email
      first_name
      last_name
      full_name
      permissions {
        permission_id
      }
      userCorporateClients {
        corporate_client_id
        corporateClient {
          name
          uuid
        }
      }
    }
  }
`;

export interface IUserContext {
  dbUser?: {
    user_id: number;
    role_id?: number;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;

    google_place_id?: string;

    permissions: Array<{
      permission_id: number;
    }>;
    userCorporateClients: Array<{
      corporate_client_id: number;
      corporateClient: {
        name: string;
        uuid: string;
      };
    }>;
    isCorporateAdmin: boolean;
    userPermissions: Record<string, boolean>;
  };

  firebaseUser?: User;
}

export const UserContext = React.createContext<IUserContext>({
  dbUser: undefined,
  firebaseUser: undefined,
});

export const UserProvider = ({ children }: any) => {
  const { currentUser } = useContext(AuthContext);
  const { data: userData, refetch } = useQuery(GET_CURRENT_USER, {
    skip: !currentUser,
  });

  useEffect(() => {
    if (currentUser) {
      refetch();
    }
  }, [currentUser, refetch]);

  const value = useMemo(
    () => ({
      dbUser: userData
        ? {
            ...userData.me,
            isCorporateAdmin: !!userData?.me.userCorporateClients?.length,
            userPermissions: {
              ...Object.keys(Permissions).reduce(
                (res: Record<string, any>, name: any) => {
                  res[name] = !!userData?.me.permissions.find(
                    (p: Record<string, any>) =>
                      p.permission_id === Permissions[name],
                  );

                  return res;
                },
                [{}],
              ),
            },
            userCorporateClients: userData?.me.userCorporateClients,
            permissions: userData?.me.permissions,
          }
        : undefined,
      firebaseUser: currentUser,
    }),
    [userData, currentUser],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
