import React, { useContext, useEffect, useState } from 'react';
import './Home.sass';
import { UserContext } from '../../../contexts/UserContext';
import { Alert } from 'antd';
import Loader from '../../../components/Loader/Loader';
import { useParams } from 'react-router-dom';

export const Home = () => {
  const { dbUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isCorpAdmin, setIsCorpAdmin] = useState(false);
  const [link, setLink] = useState<string | undefined>('');
  const params = useParams();
  const corporateClientUuId = params.uuid;

  const superAdminLink =
    'https://lookerstudio.google.com/embed/reporting/6ece632a-3fcc-47a7-a010-1235c23f4c5a/page/qBuXE';

  const getCorporateClientLink = (uuid?: string) => {
    return `https://lookerstudio.google.com/embed/reporting/786d3b9b-1740-48a7-9c76-e85eb28090f4/page/ks7BE?params=%7B%22settings.uuid%22:%22${uuid}%22,%22checkouts.uuid%22:%22${uuid}%22,%22current.uuid%22:%22${uuid}%22%7D`;
  };

  useEffect(() => {
    setLoading(true);
    setIsSuperAdmin(false);
    setIsCorpAdmin(false);

    const uuid = corporateClientUuId
      ? corporateClientUuId
      : dbUser?.userCorporateClients[0]?.corporateClient?.uuid;
    if (uuid) {
      const corpClientLink = getCorporateClientLink(uuid);
      setLink(corpClientLink);
      setIsCorpAdmin(true);
    } else {
      setLink(superAdminLink);
      setIsSuperAdmin(true);
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [link, dbUser]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : isCorpAdmin || isSuperAdmin ? (
        <div className="home-page-wrapper">
          <iframe
            className="iframe-report"
            src={link}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        </div>
      ) : (
        <Alert
          message="Warning"
          description="Unable to load corporate client data. Please check your connection or try again later."
          type="warning"
          showIcon
        />
      )}
    </>
  );
};
