import React, { useMemo } from 'react';
import { message as notifyMessage } from 'antd';
import auth from '../services/Auth/Firebase';

import {
  ApolloProvider as ApolloClientProvider,
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

if (!process.env.REACT_APP_GRAPHQL_SERVER_URI) {
  throw new Error('env REACT_APP_GRAPHQL_SERVER_URI not specified');
}

interface ApolloProviderPropsInterface {
  children: React.ReactNode;
}

export default function ApolloProvider({
  children,
}: ApolloProviderPropsInterface) {
  const client = useMemo(() => {
    const uri = process.env.REACT_APP_GRAPHQL_SERVER_URI;
    const httpLink = new HttpLink({ uri });

    const authLink = setContext(async (_, { headers = {}, noauth }) => {
      let token;

      const isAcceptInviteMutation = _.operationName === 'AcceptInvite';

      if (!noauth && !isAcceptInviteMutation) {
        // get the authentication token from firebase user
        token = await auth.currentUser?.getIdToken();
      }
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : null,
          'X-Client-Type': 'admin-panel',
        },
      };
    });

    const onErrorLink = onError(({ graphQLErrors, networkError }) => {
      let message;
      let description;

      if (graphQLErrors) {
        message = 'GraphQL error';
        description = graphQLErrors
          .map(
            ({ message, locations, path }) =>
              `Message: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
          )
          .join('\n\n');
      }

      if (networkError) {
        message = 'Network error';
        description = networkError.toString();
      }

      message &&
        notifyMessage.error(
          <div className="error-message">
            <div className="error-message-title">{message}</div>
            <div>{description}</div>
          </div>,
        );
    });

    return new ApolloClient({
      link: from([authLink, onErrorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
  }, []);

  return (
    <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
  );
}
