import { get } from 'lodash';
import { DOUBLE_VALUE_DELIMETER } from './useFilter';

export function getWhereOperation(column: any) {
  return column && column.filterOperation ? column.filterOperation : 'in';
}

export function getWhereValue(column: any, selectedKeys: any[]) {
  switch (column.filterOperation) {
    case 'isNull':
      return Boolean(selectedKeys[0]);

    case 'contains':
    case 'fullNameContains':
      return selectedKeys[0];

    default:
      return selectedKeys;
  }
}

export function normalizeBetweenNumbersFilterValue(
  values: string[] | number[] | undefined[] | undefined,
) {
  if (!values?.[0]) {
    return ['', ''];
  }

  if (typeof values[0] === 'string') {
    return values[0]?.split(DOUBLE_VALUE_DELIMETER) || ['', ''];
  }

  return (values as number[]).map((v: number) => (v ? v.toFixed() : ''));
}

export function getWhereFilter(column: any, value: any) {
  if (!column) {
    return;
  }
  const result: any = {};
  let link = result;

  if (column.filterSubField) {
    column.filterSubField.forEach((key: string) => {
      link[key] = {};
      link = link[key];
    });
  }
  let operation = getWhereOperation(column);
  let resultValue = value;
  if (operation === 'between') {
    // a filter with a BETWEEN operation has a single array element that includes two values separated by a delimiter
    // so need to separate this element with a delimiter
    // in the output we have the start and end value of the range
    resultValue = normalizeBetweenNumbersFilterValue(value);
    if (!resultValue[0]) {
      // if there is no sart value, use LT operation
      operation = 'lt';
      resultValue.shift();
    } else if (!resultValue[1]) {
      // if there is no end value, use GT operation
      operation = 'gt';
      resultValue.pop();
    }
    if (resultValue.join('') === '') return result;
    resultValue = resultValue.map((item: string) => Number(item));
    if (operation === 'between') {
      // LT works like `less than`, GT works like `more than`
      // but BETWEEN works as `more than or equal and less than or equal`
      // so we do little tricky)
      resultValue[0] += 0.0000001;
      resultValue[1] -= 0.0000001;
    }
  }
  link[operation] = getWhereValue(column, resultValue);
  return result;
}

export function normalizeOrderValue(sorter: any, key: string | string[]) {
  return typeof sorter === 'boolean'
    ? Array.isArray(key)
      ? key.join('.')
      : key
    : sorter;
}

export function getFilteredValue(col: any, where: any) {
  const whereOperation = getWhereOperation(col);

  const key = col.key || col.dataIndex;

  const getWhereKey = col.filterSubField ? `${key}.${col.filterSubField}` : key;

  let defaultFilterValue;

  // if col key includes |, it means that it is a filter for multiple fields by OR operator
  // and all values are the same in the array of this fields we can use first as render value

  if (col.key?.includes('|')) {
    defaultFilterValue = get(
      where,
      `or.[0].${col.key.split('|')[0]}.${whereOperation}`,
    );
  }

  // if filter by isNull: true
  if (get(where, getWhereKey)?.isNull) {
    defaultFilterValue = [null];
  }

  if (get(where, getWhereKey)?.[whereOperation]) {
    defaultFilterValue = get(where, getWhereKey)?.[whereOperation];
  }

  if (whereOperation === 'between') {
    const filterValue = normalizeBetweenNumbersFilterValue(
      get(where, getWhereKey)?.[whereOperation],
    );

    filterValue.filter(Boolean).length
      ? (defaultFilterValue = filterValue.join(DOUBLE_VALUE_DELIMETER))
      : null;
  }

  const filteredValue =
    defaultFilterValue !== undefined
      ? Array.isArray(defaultFilterValue)
        ? defaultFilterValue
        : [defaultFilterValue]
      : null;

  return filteredValue;
}
