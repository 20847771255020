const AdminRoles: Record<string, number> = {
  Member: 1,
  Manager: 2,
  Administrator: 3,
  InternalMember: 4,
  InternalManager: 5,
  InternalAdministrator: 6,
  SuperAdmin: 7,
};

export default AdminRoles;
