import { useCallback, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, InputRef, Radio, Row } from 'antd';

import { normalizeBetweenNumbersFilterValue } from 'components/CCCTable/utils';

export const DOUBLE_VALUE_DELIMETER = '~';

const BLOCK_STYLE = { padding: 8 };

const INPUT = { width: 188, display: 'block' };

const RADIO_STYLE = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const ButtonsBlock = ({ onConfirm, onClearFilters, children }: any) => (
  <div className="ant-table-filter-dropdown-btns">
    <a
      href="/#"
      className="ant-table-filter-dropdown-link confirm"
      onClick={(e: any) => {
        e.preventDefault();
        onConfirm();
      }}
      style={{ marginRight: 10 }}
    >
      {children || 'Ok'}
    </a>
    <a
      href="/#"
      className="ant-table-filter-dropdown-link clear"
      onClick={(e: any) => {
        e.preventDefault();
        onClearFilters();
      }}
    >
      Reset
    </a>
  </div>
);

const useFilter = (columns: any) => {
  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <>
        <div style={BLOCK_STYLE}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyDown={(e) => e.stopPropagation()}
            onPressEnter={() => confirm()}
            style={INPUT}
          />
        </div>
        <ButtonsBlock onConfirm={confirm} onClearFilters={clearFilters}>
          <SearchOutlined /> Search
        </ButtonsBlock>
      </>
    ),
    filterDropdownProps: {
      onOpenChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select());
        }
      },
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const getColumnRadioFilterProps = useCallback(
    (dataIndex: string) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <>
          <div style={BLOCK_STYLE}>
            <Radio.Group
              onChange={(e: any) => {
                setSelectedKeys(
                  typeof e.target.value === 'boolean' ? [e.target.value] : [],
                );
              }}
              value={selectedKeys[0]}
            >
              {columns
                .find(
                  ({ dataIndex: i, key }: any) =>
                    i === dataIndex || key === dataIndex,
                )
                .filters.map(({ value, text }: any) => (
                  <Radio key={value} style={RADIO_STYLE} value={value}>
                    {text}
                  </Radio>
                ))}
            </Radio.Group>
          </div>
          <ButtonsBlock onConfirm={confirm} onClearFilters={clearFilters} />
        </>
      ),
    }),
    [columns],
  );

  const getColumnNumberSearch = () => ({
    filterOperation: 'between',
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => {
      // this filter has two values that are should be connected by AND logic
      // since the admin/src/components/CCCTable/CCCTable.tsx onChange event in the Table component handle an array of filter values with OR logic, we need to put both values of this filter into one element and separate it with delimiter for parsing later
      const prepareSetSelectedKeys = (v1: string, v2: string) => {
        const result: (string | null)[] = [
          `${v1}${DOUBLE_VALUE_DELIMETER}${v2}`,
        ];
        const numV1 = Number(v1);
        const numV2 = Number(v2);
        if (
          (numV1 < 0 && numV2 > 0) ||
          (numV1 < 0 && v2 === '') ||
          (numV2 > 0 && v1 === '')
        ) {
          // there are cases when there are NULL values in the collection, but we show 0 for the user such values will not be included in the result, so we add NULL to rules where it is necessary
          result.push(null);
        }
        return result;
      };

      const values = normalizeBetweenNumbersFilterValue(selectedKeys);

      return (
        <>
          <div style={{ ...BLOCK_STYLE, ...INPUT }}>
            <Row gutter={[8, 4]}>
              <Col span={12}>Bigger than:</Col>
              <Col span={12}>
                <Input
                  placeholder={`- infinity`}
                  value={values[0]}
                  onChange={(e) => {
                    setSelectedKeys(
                      prepareSetSelectedKeys(e.target.value || '', values[1]),
                    );
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  onPressEnter={() => confirm()}
                />
              </Col>
              <Col span={12}>Less than:</Col>
              <Col span={12}>
                <Input
                  placeholder={`+ infinity`}
                  value={values[1]}
                  onChange={(e) => {
                    setSelectedKeys(
                      prepareSetSelectedKeys(values[0], e.target.value || ''),
                    );
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  onPressEnter={() => confirm()}
                />
              </Col>
            </Row>
          </div>
          <ButtonsBlock onConfirm={confirm} onClearFilters={clearFilters} />
        </>
      );
    },
  });

  return {
    getColumnSearchProps,
    getColumnRadioFilterProps,
    getColumnNumberSearch,
  };
};

export default useFilter;
