import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_PERMISSIONS } from 'queries/DictionariesQueries';

const { Option } = Select;

const PermissionsSelect = forwardRef(
  ({ value, isEditingEnabled, ...props }: any, ref: any) => {
    const { data, error } = useQuery(QUERY_LIST_PERMISSIONS);

    return (
      <Select
        ref={ref}
        value={value}
        mode="multiple"
        disabled={!isEditingEnabled}
        style={{ width: '100%' }}
        showSearch
        filterOption={(input, option) => {
          const label = (option?.children || option?.label) as string;
          return label?.toLowerCase().includes(input.toLowerCase());
        }}
        {...props}
      >
        {data && !error
          ? data.listPermission.map((l: any) => (
              <Option key={l.permission_id} value={`${l.permission_id}`}>
                {l.name}
              </Option>
            ))
          : null}
      </Select>
    );
  },
);

PermissionsSelect.displayName = 'PermissionsSelect';

export default PermissionsSelect;
