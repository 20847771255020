import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  message,
  Popconfirm,
  Select,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import PermissionsSelect from '../../../../components/Select/PermissionsSelect';
import { QRCode } from 'react-qrcode-logo';
import CorporateClientSelect from '../../../../components/Select/CorporateClientSelect';
import { Buffer } from 'buffer';
import { UserContext } from '../../../../contexts/UserContext';
import {
  GET_ADMIN_ROLES,
  MUTATION_UNFREEZE_USER,
} from '../../../../queries/UserQueries';
import { PRIMARY_COLOR } from '../../../../App';
import AdminRoles from '../../../../constants/AdminRoles';
import { AdminRole } from '../../CorporateClients/utils/corpClientTypes';

const { Title, Text } = Typography;

const UserForm = ({ handleSubmit, user, refetchUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { dbUser } = useContext(UserContext);
  const mode = user ? 'update' : 'create';

  const [unfreezeUser] = useMutation(MUTATION_UNFREEZE_USER);

  const [selectedCorporateClient, setSelectedCorporateClient] = useState<
    number | undefined
  >(user?.userCorporateClients?.[0]?.corporate_client_id || undefined);

  const { data: adminRolesData, refetch: refetchAdminRoles } = useQuery(
    GET_ADMIN_ROLES,
    {
      variables: { corporate_client_id: selectedCorporateClient },
      fetchPolicy: 'network-only',
    },
  );
  const [form] = Form.useForm();

  useEffect(() => {
    user &&
      form.setFieldsValue({
        ...user,
        role_id: user.role_id,
        permissions: (user.permissions || []).map(
          (c: any) => c.permission_id + '',
        ),
        userCorporateClients: (user.userCorporateClients || []).map(
          (c: any) => c.corporate_client_id + '',
        ),
      });
  }, [form, user]);

  useEffect(() => {
    if (adminRolesData?.getAdminRoles) {
      const selectedRole = adminRolesData.getAdminRoles.find(
        (role: AdminRole) => role.role_id === form.getFieldValue('role_id'),
      );
      if (selectedRole) {
        form.setFieldsValue({
          permissions: selectedRole.permissions.map(
            (p: any) => `${p.permission_id}`,
          ),
        });
      }
    }
  }, [adminRolesData, form]);

  const submit = useCallback(async () => {
    const values = await form.validateFields();

    setLoading(true);
    await handleSubmit(values);
    setLoading(false);
  }, [form, handleSubmit]);

  const userMembership = useMemo(
    () => user.user_membership.find((m: Record<string, any>) => !m.to),
    [user],
  );

  return (
    <>
      <Row gutter={10}>
        <Form
          layout="vertical"
          name="user_create"
          form={form}
          onFinish={submit}
        >
          <Row>
            <Col span={24}>
              <Title level={2}>
                {mode === 'create'
                  ? 'Create User'
                  : `Update User "${
                      user.first_name
                        ? user.first_name + ' ' + user.last_name
                        : user.email
                    }"`}
              </Title>
            </Col>

            {user.firebase_user_id && (
              <Col span={24}>
                <QRCode
                  value={Buffer.from(`user:${user.firebase_user_id}`).toString(
                    'base64',
                  )}
                />
              </Col>
            )}

            <Col span={24} style={{ margin: '20px 0' }}>
              <Form.Item label="Email" name="email">
                <Input type="email" placeholder="First Name" disabled />
              </Form.Item>

              <Form.Item label="First Name" name="first_name">
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item label="Last Name" name="last_name">
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item label="Membership Level">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    type={user.current_client_level ? 'success' : 'danger'}
                    strong
                  >
                    {user.current_client_level?.membershipLevel?.name ||
                      'No any active subscription'}
                  </Text>
                  {!!userMembership?.locked_by_transaction_id && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          border: '1px solid red',
                          borderRadius: '6px',
                          marginLeft: 12,
                          marginRight: 12,
                          padding: '4px',
                        }}
                      >
                        <Text type={'danger'} strong>
                          FROZEN
                        </Text>
                      </div>
                      {dbUser?.userPermissions.DangerZone && (
                        <>
                          <div className="assignment-space space-lg" />
                          {contextHolder}
                          <Popconfirm
                            title="Did you void the user's fee already?"
                            onConfirm={async () => {
                              const unfreezeRes = await unfreezeUser({
                                variables: {
                                  user_id: user.user_id,
                                },
                              });

                              if (unfreezeRes?.data?.unfreezeUser?.success) {
                                refetchUser();
                              } else if (
                                unfreezeRes?.data?.unfreezeUser?.error_message
                              ) {
                                messageApi.error(
                                  unfreezeRes.data.unfreezeUser.error_message
                                    .message,
                                );
                              }
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              style={{
                                borderWidth: '1px',
                                borderColor: PRIMARY_COLOR,
                                color: PRIMARY_COLOR,
                              }}
                            >
                              Activate
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Form.Item>
              <Form.Item label="Community">
                <Text
                  type={user.current_client_level ? 'success' : 'danger'}
                  strong
                >
                  {user.current_client_level?.corporateClient
                    ? user.current_client_level?.corporateClient?.name
                    : user.current_client_level
                    ? 'DEFAULT'
                    : 'No any active subscription with some community'}
                </Text>
              </Form.Item>
              <Form.Item label="Payment Processor">
                <Text type={userMembership ? 'success' : 'danger'} strong>
                  {userMembership?.paymentProcessor?.name || 'Not Found'}
                </Text>
              </Form.Item>

              <Form.Item label="Admin Roles" name="role_id">
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select admin role"
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldsValue({
                        role_id: null,
                        permissions: [],
                      });
                    } else {
                      const selectedRole: AdminRole =
                        adminRolesData?.getAdminRoles.find(
                          (role: AdminRole) => role?.role_id === value,
                        );
                      if (selectedRole) {
                        // Update permissions based on the selected role
                        form.setFieldsValue({
                          role_id: selectedRole.role_id,
                          permissions: selectedRole.permissions.map(
                            (p: any) => `${p.permission_id}`,
                          ),
                        });
                      }
                    }
                  }}
                >
                  {dbUser && dbUser.userPermissions.RemoveAdminAccess && (
                    <Select.Option value={0} key="none">
                      (none)
                    </Select.Option>
                  )}

                  {adminRolesData?.getAdminRoles?.map((role: AdminRole) => (
                    <Select.Option
                      key={role.role_id}
                      value={role.role_id}
                      disabled={!role.isEnabled}
                    >
                      {role.name} {/* Displaying the role name */}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Admin Permissions" name="permissions">
                <PermissionsSelect
                  placeholder="Select admin permissions"
                  isEditingEnabled={AdminRoles.SuperAdmin === dbUser?.role_id}
                />
              </Form.Item>

              <Form.Item
                label="Admin Of Corporate Clients"
                name="userCorporateClients"
              >
                <CorporateClientSelect
                  placeholder="Select Corporate Clients"
                  value={selectedCorporateClient}
                  onChange={(value: string) => {
                    setSelectedCorporateClient(Number(value));
                    refetchAdminRoles({ corporate_client_id: Number(value) });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {mode === 'create' ? 'Save' : 'Update'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      {contextHolder}
    </>
  );
};

export default UserForm;
