import { useState, useMemo, useCallback } from 'react';

const PAGE_SIZE = 25;
const pageSizeOptions = ['25', '50', '100', '300', '500', '1000'];

const usePagination = () => {
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const onShowSizeChange = useCallback((_: any, s: any) => setPageSize(s), []);

  const handleChange = useCallback(({ current }: any) => setPage(current), []);
  const handleTotal = useCallback((t: number) => setTotal(t), []);

  const getPagination = useCallback(() => {
    return {
      page,
      size: pageSize,
    };
  }, [page, pageSize]);

  return {
    handleTotal,
    handleChange,
    getPagination,

    pagination: useMemo(
      () => ({
        total,
        page,
        pageSize,
        current: page,
        showTotal: (total: number) => `Total ${total} items`,
        showSizeChanger: true,
        pageSizeOptions,
        onShowSizeChange,
      }),
      [total, page, pageSize, onShowSizeChange],
    ),
  };
};

export default usePagination;
